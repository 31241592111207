/* eslint-disable max-len */
export default {
  timelines: [
    {
      id: 1,
      startAt: '0:00',
      respawnId: 0,
      complexity: 0,
      direction: 'top',
      endAt: '29:32',
      events: [
        {
          timeline: '1',
          type: 'SeasonSeparator',
          episode: '1A',
          episodeRelativeTC: '0:00',
          timecode: '0:00',
          absoluteTC: '0:00',
          description: 'Season 1',
        },
        {
          timeline: '1',
          type: 'Event',
          episode: '1A',
          episodeRelativeTC: '2:00',
          timecode: '2:00',
          absoluteTC: '2:00',
          description: 'Subaru spawns next to the Lugnica fountain.',
          image: '/images/events/subaru-spawns-lugnica.png',
        },
        {
          timeline: '1',
          type: 'RespawnPoint',
          episode: '1A',
          episodeRelativeTC: '3:43',
          timecode: '3:43',
          absoluteTC: '3:43',
          description: 'Subaru talks to a merchant.',
          id: '1',
          image: '/images/respawns/respawn-1.png',
        },
        {
          timeline: '1',
          type: 'Event',
          episode: '1A',
          episodeRelativeTC: '5:28',
          timecode: '5:28',
          absoluteTC: '5:28',
          description: 'Subaru encounters three thugs in a dark alleyway. Emilia, who were chasing Felt, scares away the thugs and heals Subaru.',
          image: '/images/events/alleyway-emilia.png',
        },
        {
          timeline: '1',
          type: 'Event',
          episode: '1B',
          episodeRelativeTC: '1:00',
          timecode: '26:16',
          absoluteTC: '26:16',
          description: 'Subaru and Emilia arrive at Rom\'s tavern but Subaru decides to enter alone.',
          image: '/images/events/front-tavern.png',
        },
        {
          timeline: '1',
          type: 'Death',
          episode: '1B',
          episodeRelativeTC: '4:16',
          timecode: '29:32',
          absoluteTC: '29:32',
          description: 'A woman hidden in the dark stabs Subaru. After hearing strange noises coming from the inside, Emilia enters the tavern and gets stabbed.',
          image: '/images/deaths/death-1.png',
        },
      ],
    },
    {
      id: 2,
      startAt: '29:32',
      respawnId: 1,
      complexity: 1,
      direction: 'top',
      endAt: '47:54',
      events: [
        {
          timeline: '2',
          type: 'Event',
          episode: '1B',
          episodeRelativeTC: '5:28',
          timecode: '1:12',
          absoluteTC: '30:44',
          description: 'Subaru doesn\'t understand what happened but he hurries to find Emilia. In the same alleyway, he encounters the three thugs but this time he kicks the shit out of them and rushes to the tavern.',
          image: '/images/events/confused-subaru.png',
        },
        {
          timeline: '2',
          type: 'Event',
          episode: '1B',
          episodeRelativeTC: '7:20',
          timecode: '3:04',
          absoluteTC: '32:36',
          description: 'Subaru enters the tavern and negotiates with Rom, Felt and Elsa to buy back Emilia\'s badge.',
          image: '/images/events/tavern-negotiations.png',
        },
        {
          timeline: '2',
          type: 'Death',
          episode: '1B',
          episodeRelativeTC: '22:38',
          timecode: '18:22',
          absoluteTC: '47:54',
          description: 'After winning the negotiations, Subaru reveals his intentions to give back the badge to Emilia. Elsa does not agree and kills Rom, Felt and Subaru.',
          image: '/images/deaths/death-2.png',
        },
      ],
    },
    {
      id: 3,
      startAt: '47:54',
      respawnId: 1,
      complexity: 2,
      direction: 'top',
      endAt: '53:40',
      events: [
        {
          timeline: '3',
          type: 'Event',
          episode: '2',
          episodeRelativeTC: '0:55',
          timecode: '2:33',
          absoluteTC: '50:27',
          description: 'Felt steals Emilia\'s insigna.',
          image: '/images/events/felt-steals-insigna.png',
        },
        {
          timeline: '3',
          type: 'Death',
          episode: '2',
          episodeRelativeTC: '4:08',
          timecode: '5:46',
          absoluteTC: '53:40',
          description: 'Subaru is stabbed by the three thugs.',
          image: '/images/deaths/death-3.png',
        },
      ],
    },
    {
      id: 4,
      startAt: '53:40',
      respawnId: 1,
      complexity: 3,
      direction: 'top',
      endAt: '2:02:37',
      events: [
        {
          timeline: '4',
          type: 'Event',
          episode: '2',
          episodeRelativeTC: '8:52',
          timecode: '4:44',
          absoluteTC: '58:24',
          description: 'Subaru encounters the three thugs and screams for help.',
          image: '/images/events/subaru-screams-alleyway.png',
        },
        {
          timeline: '4',
          type: 'Event',
          episode: '2',
          episodeRelativeTC: '13:30',
          timecode: '9:22',
          absoluteTC: '1:03:02',
          description: 'Subaru accidentally bumps into Elsa while looking for Felt.',
          image: '/images/events/subaru-bumps-into-elsa.png',
        },
        {
          timeline: '4',
          type: 'Event',
          episode: '3',
          episodeRelativeTC: '2:17',
          timecode: '33:58',
          absoluteTC: '1:27:38',
          description: 'Subaru, Emilia, Rom, Felt and Reinhart fight Elsa.',
          image: '/images/events/reinhart-fights-elsa.png',
        },
        {
          timeline: '4',
          type: 'RespawnPoint',
          episode: '4',
          episodeRelativeTC: '4:24',
          timecode: '49:27',
          absoluteTC: '1:43:07',
          description: 'Subaru wakes up with Rem and Ram at his bedside.',
          id: '2',
          image: '/images/respawns/respawn-2.png',
        },
        {
          timeline: '4',
          type: 'Event',
          episode: '4',
          episodeRelativeTC: '13:34',
          timecode: '58:37',
          absoluteTC: '1:52:17',
          description: 'Roswaal grants a wish to Subaru for helping Emilia. He decides to become a butler for the mansion.',
          image: '/images/events/subaru-becomes-butler.png',
        },
        {
          timeline: '4',
          type: 'Death',
          episode: '4',
          episodeRelativeTC: '23:54',
          timecode: '1:08:57',
          absoluteTC: '2:02:37',
          description: 'In the middle of the night of the 4th day, Subaru inexplicably dies in his sleep.',
          image: '/images/deaths/death-4.png',
        },
      ],
    },
    {
      id: 5,
      startAt: '2:02:37',
      respawnId: 2,
      complexity: 1,
      direction: 'top',
      endAt: '2:27:50',
      events: [
        {
          timeline: '5',
          type: 'Event',
          episode: '5',
          episodeRelativeTC: '5:07',
          timecode: '5:49',
          absoluteTC: '2:08:26',
          description: 'Subaru becomes a butler once again to do the same actions has the previous loop and understand who kill him.',
          image: '/images/events/subaru-becomes-butler.png',
        },
        {
          timeline: '5',
          type: 'Event',
          episode: '5',
          episodeRelativeTC: '15:01',
          timecode: '15:43',
          absoluteTC: '2:18:20',
          description: 'Rem and Subaru go to the village next door.',
          image: '/images/events/inside-village.png',
        },
        {
          timeline: '5',
          type: 'Death',
          episode: '5',
          episodeRelativeTC: '24:31',
          timecode: '25:13',
          absoluteTC: '2:27:50',
          description: 'In the middle of the night, Subaru feels sick and get out of his room. He hears chains in the distance and, without being able to retaliate, he is killed.',
          image: '/images/deaths/death-5.png',
        },
      ],
    },
    {
      id: 6,
      startAt: '2:27:50',
      respawnId: 2,
      complexity: 2,
      direction: 'top',
      endAt: '2:57:03',
      events: [
        {
          timeline: '6',
          type: 'Event',
          episode: '6',
          episodeRelativeTC: '8:42',
          timecode: '8:46',
          absoluteTC: '2:36:36',
          description: 'This time, Subaru becomes a guest to gather informations about the killer.',
          image: '/images/events/subaru-becomes-guest.png',
        },
        {
          timeline: '6',
          type: 'Event',
          episode: '6',
          episodeRelativeTC: '21:27',
          timecode: '21:31',
          absoluteTC: '2:49:21',
          description: 'After four days, Subaru leaves Roswaal\'s mansion to wait for the killer in the forest.',
          image: '/images/events/subaru-forest-near-mansion.png',
        },
        {
          timeline: '6',
          type: 'Death',
          episode: '7',
          episodeRelativeTC: '4:33',
          timecode: '29:13',
          absoluteTC: '2:57:03',
          description: 'Rem attacks Subaru because he stinks the Witch. She cuts Subaru\'s right leg and questions him. Ram finishes him off by cutting his throat.',
          image: '/images/deaths/death-6.png',
        },
      ],
    },
    {
      id: 7,
      startAt: '2:57:03',
      respawnId: 2,
      complexity: 3,
      direction: 'top',
      endAt: '3:17:05',
      events: [
        {
          timeline: '7',
          type: 'Event',
          episode: '7',
          episodeRelativeTC: '5:44',
          timecode: '1:11',
          absoluteTC: '2:58:14',
          description: 'Subaru tries to tell Emilia about his ability to respawn.',
          image: '/images/events/subaru-tries-to-confess.png',
        },
        {
          timeline: '7',
          type: 'Event',
          episode: '7',
          episodeRelativeTC: '8:50',
          timecode: '4:17',
          absoluteTC: '3:01:20',
          description: 'Beatrice visits Subaru in his room. He asks for her protection until the morning of the fifth day.',
          image: '/images/events/beatrice-protects-subaru.png',
        },
        {
          timeline: '7',
          type: 'Event',
          episode: '7',
          episodeRelativeTC: '13:50',
          timecode: '9:17',
          absoluteTC: '3:06:20',
          description: 'On the morning of the 5th day, Subaru leaves Beatrice\'s library and finds Rem dead because of a curse.',
          image: '/images/events/rem-cursed.png',
        },
        {
          timeline: '7',
          type: 'Death',
          episode: '7',
          episodeRelativeTC: '24:35',
          timecode: '20:02',
          absoluteTC: '3:17:05',
          description: 'Ram chases Subaru outside the mansion to kill him. Beatrice is still protection Subaru but he jumps off the cliff.',
          image: '/images/deaths/death-7.png',
        },
      ],
    },
    {
      id: 8,
      startAt: '3:17:05',
      respawnId: 2,
      complexity: 4,
      direction: 'top',
      endAt: '6:13:40',
      events: [
        {
          timeline: '8',
          type: 'Event',
          episode: '8',
          episodeRelativeTC: '3:30',
          timecode: '3:31',
          absoluteTC: '3:20:36',
          description: 'Subaru becomes a butler for the third time.',
          image: '/images/events/subaru-becomes-butler-2.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '8',
          episodeRelativeTC: '18:35',
          timecode: '18:36',
          absoluteTC: '3:35:41',
          description: 'Subaru struggles to hide his emotions and bursts into tears on Emilia\'s knees.',
          image: '/images/events/subaru-on-emilia-lap.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '9',
          episodeRelativeTC: '0:12',
          timecode: '24:49',
          absoluteTC: '3:41:54',
          description: 'Subaru, Rem and Ram go to Irlam village.',
          image: '/images/events/kids-laughing.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '9',
          episodeRelativeTC: '8:01',
          timecode: '32:38',
          absoluteTC: '3:49:43',
          description: 'Subaru and Rem quickly returns to the village because Beatrice tells him that the curse comes from it.',
          image: '/images/events/subaru-rem-leaving-mansion.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '9',
          episodeRelativeTC: '22:41',
          timecode: '47:18',
          absoluteTC: '4:04:23',
          description: 'Subaru protects Rem and is severely wounded.',
          image: '/images/events/subaru-attacked-by-dogs.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '10',
          episodeRelativeTC: '11:27',
          timecode: '1:00:40',
          absoluteTC: '4:17:45',
          description: 'To prevent Subaru from dying of his many curses, Rem goes alone in the forest to kill all the beast (Wolgarm). Ram and Subaru join her and Roswaal saves them.',
          image: '/images/events/subaru-and-big-dog.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '12',
          episodeRelativeTC: '6:01',
          timecode: '1:44:26',
          absoluteTC: '5:01:31',
          description: 'Emilia, Subaru and Rem go to Lugnica.',
          image: '/images/events/lugnica-road.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '12',
          episodeRelativeTC: '16:59',
          timecode: '1:55:24',
          absoluteTC: '5:12:29',
          description: 'The next day, Emilia and Roswaal return to Lugnica. Subaru goes to the capital even though Emilia said him not to.',
          image: '/images/events/subaru-in-front-royal-castle.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '13',
          episodeRelativeTC: '15:00',
          timecode: '2:18:00',
          absoluteTC: '5:35:05',
          description: 'Subaru gets totaly smashed by Julius.',
          image: '/images/events/subaru-smashed-by-julius.png',
        },
        {
          timeline: '8',
          type: 'RespawnPoint',
          episode: '14',
          episodeRelativeTC: '7:59',
          timecode: '2:35:35',
          absoluteTC: '5:52:40',
          description: 'Subaru and Rem talk to the apple vendor.',
          id: '3',
          image: '/images/respawns/respawn-3.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '14',
          episodeRelativeTC: '12:48',
          timecode: '2:40:24',
          absoluteTC: '5:57:29',
          description: 'Subaru fails to convince Crusch to help him save Emilia.',
          image: '/images/events/subaru-tries-convincing-crusch.png',
        },
        {
          timeline: '8',
          type: 'Event',
          episode: '14',
          episodeRelativeTC: '20:18',
          timecode: '2:47:54',
          absoluteTC: '6:04:59',
          description: 'Rem abandons Subaru and goes to the mansion alone.',
          image: '/images/events/empty-room.png',
        },
        {
          timeline: '8',
          type: 'Death',
          episode: '15',
          episodeRelativeTC: '3:03',
          timecode: '2:56:35',
          absoluteTC: '6:13:40',
          description: 'Looking for Emilia, Subaru finds a secret corridor in the mansion and is freezed to death by Pack.',
          image: '/images/deaths/death-8.png',
        },
      ],
    },
    {
      id: 9,
      startAt: '6:13:40',
      respawnId: 3,
      complexity: 1,
      direction: 'top',
      endAt: '6:32:22',
      events: [
        {
          timeline: '9',
          type: 'Event',
          episode: '15',
          episodeRelativeTC: '6:20',
          timecode: '3:17',
          absoluteTC: '6:16:57',
          description: 'Subaru\'s mind is completely broken, Rem decides to take him to Emilia.',
          image: '/images/events/mad-subaru-hugs-rem.png',
        },
        {
          timeline: '9',
          type: 'Event',
          episode: '15',
          episodeRelativeTC: '14:14',
          timecode: '11:11',
          absoluteTC: '6:24:51',
          description: 'Rem tries to save Subaru from Betelgeuse.',
          image: '/images/events/twisted-rem.png',
        },
        {
          timeline: '9',
          type: 'Death',
          episode: '15',
          episodeRelativeTC: '21:45',
          timecode: '18:42',
          absoluteTC: '6:32:22',
          description: 'Subaru arrives at the mansion, an enourmous creature emerges from the mansion, freezes and beheads Subaru.',
          image: '/images/deaths/death-9.png',
        },
      ],
    },
    {
      id: 10,
      startAt: '6:32:22',
      respawnId: 3,
      complexity: 2,
      direction: 'top',
      endAt: '7:28:04',
      events: [
        {
          timeline: '10',
          type: 'Event',
          episode: '16',
          episodeRelativeTC: '1:12',
          timecode: '4:03',
          absoluteTC: '6:36:25',
          description: 'Subaru tries to convince Crusch to help him save Emilia.',
          image: '/images/events/subaru-tries-convincing-crusch-2.png',
        },
        {
          timeline: '10',
          type: 'Event',
          episode: '16',
          episodeRelativeTC: '11:11',
          timecode: '14:02',
          absoluteTC: '6:46:24',
          description: 'Subaru fails to convince Priscilla to help him save Emilia.',
          image: '/images/events/angry-priscilla.png',
        },
        {
          timeline: '10',
          type: 'Event',
          episode: '16',
          episodeRelativeTC: '17:37',
          timecode: '20:28',
          absoluteTC: '6:52:50',
          description: 'Subaru fails to convince Anastasia to help him save Emilia.',
          image: '/images/events/subaru-talks-with-anastasia.png',
        },
        {
          timeline: '10',
          type: 'Event',
          episode: '17',
          episodeRelativeTC: '5:06',
          timecode: '32:32',
          absoluteTC: '7:04:54',
          description: 'Rem sacrifices herself to allow Subaru to flee the Great Whale.',
          image: '/images/events/rem-great-whale.png',
        },
        {
          timeline: '10',
          type: 'Event',
          episode: '17',
          episodeRelativeTC: '18:56',
          timecode: '46:22',
          absoluteTC: '7:18:44',
          description: 'Subaru tries to tell Emilia about his ability to respawn, but the Witch of Envy kills her.',
          image: '/images/events/subaru-holds-dead-emilia.png',
        },
        {
          timeline: '10',
          type: 'Death',
          episode: '18',
          episodeRelativeTC: '3:40',
          timecode: '55:42',
          absoluteTC: '7:28:04',
          description: 'Subaru is attacked by the Cult, Pack kills everyone.',
          image: '/images/deaths/death-10.png',
        },
      ],
    },
    {
      id: 11,
      startAt: '7:28:04',
      respawnId: 3,
      complexity: 3,
      direction: 'top',
      endAt: '9:52:51',
      events: [
        {
          timeline: '11',
          type: 'Event',
          episode: '19',
          episodeRelativeTC: '7:14',
          timecode: '29:30',
          absoluteTC: '7:57:34',
          description: 'Subaru convinces Anastasia and Crusch to help him save Emilia from the cult.',
          image: '/images/events/subaru-shakes-hands-with-crusch.png',
        },
        {
          timeline: '11',
          type: 'Event',
          episode: '19',
          episodeRelativeTC: '22:59',
          timecode: '45:15',
          absoluteTC: '8:13:19',
          description: 'Subaru\'s phone starts ringing, the fight with the White Whale begins.',
          image: '/images/events/great-whale.png',
        },
        {
          timeline: '11',
          type: 'Event',
          episode: '21',
          episodeRelativeTC: '14:50',
          timecode: '1:26:18',
          absoluteTC: '8:54:22',
          description: 'Willhem finishes off the White Whale.',
          image: '/images/events/great-whale-eye.png',
        },
        {
          timeline: '11',
          type: 'RespawnPoint',
          episode: '22',
          episodeRelativeTC: '1:26',
          timecode: '1:37:23',
          absoluteTC: '9:05:27',
          description: 'Subaru briefs his unit before going to Roswaal Mansion.',
          id: '4',
          image: '/images/respawns/respawn-4.png',
        },
        {
          timeline: '11',
          type: 'Event',
          episode: '22',
          episodeRelativeTC: '11:15',
          timecode: '1:47:12',
          absoluteTC: '9:15:16',
          description: 'Willhem kills Betelgeuse by surprise while Subaru makes the bait.',
          image: '/images/events/willhem-kills-betelgeuse.png',
        },
        {
          timeline: '11',
          type: 'Death',
          episode: '23',
          episodeRelativeTC: '24:22',
          timecode: '2:24:47',
          absoluteTC: '9:52:51',
          description: 'Betelgeuse takes control of Subaru\'s body, Ferris immobilizes Subaru and Julius finishes him.',
          image: '/images/deaths/death-11-2.png',
        },
      ],
    },
    {
      id: 12,
      startAt: '9:52:51',
      respawnId: 4,
      complexity: 1,
      direction: 'top',
      endAt: '10:58:38',
      events: [
        {
          timeline: '12',
          type: 'Event',
          episode: '24',
          episodeRelativeTC: '18:00',
          timecode: '18:07',
          absoluteTC: '10:10:58',
          description: 'Subaru provokes Betelgeuse and the chase begins.',
          image: '/images/events/subaru-chased-by-betelgeuse.png',
        },
        {
          timeline: '12',
          type: 'Event',
          episode: '25',
          episodeRelativeTC: '17:23',
          timecode: '41:58',
          absoluteTC: '10:34:49',
          description: 'Subaru takes away all the magic stones of fire that were hidden in Emilia\'s carriage to make them blow up away from anyone.',
          image: '/images/events/explosion.png',
        },
        {
          timeline: '12',
          type: 'SeasonSeparator',
          episode: '26',
          episodeRelativeTC: '0:00',
          timecode: '51:55',
          absoluteTC: '10:44:46',
          description: 'Season 2 (Part I)',
        },
        {
          timeline: '12',
          type: 'RespawnPoint',
          episode: '26',
          episodeRelativeTC: '3:55',
          timecode: '55:49',
          absoluteTC: '10:48:40',
          description: 'Subaru talks about Rem with Emilia in the horse-drawn cart. Crusch unit is attacked by greed and gluttony archbishops.',
          id: '5',
          image: '/images/respawns/respawn-5.png',
        },
        {
          timeline: '12',
          type: 'Death',
          episode: '26',
          episodeRelativeTC: '13:53',
          timecode: '1:05:47',
          absoluteTC: '10:58:38',
          description: 'Subaru kills himself by sticking a knife in his throat after seeing Rem dead.',
          image: '/images/deaths/death-12.png',
        },
      ],
    },
    {
      id: 13,
      startAt: '10:58:38',
      respawnId: 5,
      complexity: 1,
      direction: 'top',
      endAt: '13:00:11',
      events: [
        {
          timeline: '13',
          type: 'Event',
          episode: '27',
          episodeRelativeTC: '19:59',
          timecode: '34:46',
          absoluteTC: '11:33:24',
          description: 'Subaru, Emilia and Otto head towards the Sanctuary of Kremaldy to join Roswaal.',
          image: '/images/events/horse-drawn-cart.png',
        },
        {
          timeline: '13',
          type: 'Event',
          episode: '27',
          episodeRelativeTC: '23:45',
          timecode: '38:32',
          absoluteTC: '11:37:10',
          description: 'Subaru is invoked in Echidna\'s tea party.',
          image: '/images/events/echidna-tea-party.png',
        },
        {
          timeline: '13',
          type: 'Event',
          episode: '28',
          episodeRelativeTC: '15:11',
          timecode: '55:38',
          absoluteTC: '11:54:16',
          description: 'Subaru and Emilia talk to a wrapped in bandage Roswaal and learn that they are all stuck in the Sanctuary until someone succesfully passes all 3 trials.',
          image: '/images/events/bandage-roswaal-talk.png',
        },
        {
          timeline: '13',
          type: 'RespawnPoint',
          episode: '30',
          episodeRelativeTC: '5:54',
          timecode: '1:41:21',
          absoluteTC: '12:39:59',
          description: 'Subaru successfully passes the first step of Echidna temple.',
          id: '6',
          image: '/images/respawns/respawn-6.png',
        },
        {
          timeline: '13',
          type: 'Event',
          episode: '30',
          episodeRelativeTC: '22:48',
          timecode: '1:58:15',
          absoluteTC: '12:56:53',
          description: 'Subaru guides the villagers who can leave the Sanctuary and returns to the mansion to check that everything is okay.',
          image: '/images/events/subaru-garfiel-cart.png',
        },
        {
          timeline: '13',
          type: 'Death',
          episode: '30',
          episodeRelativeTC: '26:06',
          timecode: '2:01:33',
          absoluteTC: '13:00:11',
          description: 'Elsa rip open Subaru by surprise in the mansion.',
          image: '/images/deaths/death-13.png',
        },
      ],
    },
    {
      id: 14,
      startAt: '13:00:11',
      respawnId: 6,
      complexity: 1,
      direction: 'top',
      endAt: '13:31:34',
      events: [
        {
          timeline: '14',
          type: 'Event',
          episode: '31',
          episodeRelativeTC: '8:18',
          timecode: '8:21',
          absoluteTC: '13:08:32',
          description: 'Ram and Subaru return to the mansion.',
          image: '/images/events/petra-greets-subaru-ram.png',
        },
        {
          timeline: '14',
          type: 'Event',
          episode: '31',
          episodeRelativeTC: '16:24',
          timecode: '16:27',
          absoluteTC: '13:16:38',
          description: 'Elsa arrives at the mansion with Petra in hostage.',
          image: '/images/events/petra-hostage-elsa.png',
        },
        {
          timeline: '14',
          type: 'Death',
          episode: '32',
          episodeRelativeTC: '5:10',
          timecode: '31:23',
          absoluteTC: '13:31:34',
          description: 'Elsa enters Beatrice\'s library and kills Subaru.',
          image: '/images/deaths/death-14.png',
        },
      ],
    },
    {
      id: 15,
      startAt: '13:31:34',
      respawnId: 6,
      complexity: 2,
      direction: 'top',
      endAt: '14:09:57',
      events: [
        {
          timeline: '15',
          type: 'Event',
          episode: '32',
          episodeRelativeTC: '14:19',
          timecode: '9:09',
          absoluteTC: '13:40:43',
          description: 'Lews Cima and Garfiel lock up Subaru inside an underground dungeon.',
          image: '/images/events/subaru-tied-up.png',
        },
        {
          timeline: '15',
          type: 'Death',
          episode: '33',
          episodeRelativeTC: '17:03',
          timecode: '38:23',
          absoluteTC: '14:09:57',
          description: 'Subaru is eatten alive by the Great Rabbit.',
          image: '/images/deaths/death-15.png',
        },
      ],
    },
    {
      id: 16,
      startAt: '14:09:57',
      respawnId: 6,
      complexity: 3,
      direction: 'top',
      endAt: '14:46:28',
      events: [
        {
          timeline: '16',
          type: 'Event',
          episode: '33',
          episodeRelativeTC: '17:40',
          timecode: '0:37',
          absoluteTC: '14:10:34',
          description: 'Subaru is invoked in Echidna\'s tea party for the second time.',
          image: '/images/events/echidna-close-up.png',
        },
        {
          timeline: '16',
          type: 'Event',
          episode: '34',
          episodeRelativeTC: '7:40',
          timecode: '14:47',
          absoluteTC: '14:24:44',
          description: 'Subaru meets Typhon (Witch of Pride), Minerva (Witch of Wrath) and Daphne (Witch of Gluttony).',
          image: '/images/events/subaru-breaking-apart.png',
        },
        {
          timeline: '16',
          type: 'Event',
          episode: '34',
          episodeRelativeTC: '20:30',
          timecode: '27:37',
          absoluteTC: '14:37:34',
          description: 'As Subaru exits the temple, Satella appears right in front of him.',
          image: '/images/events/dark-satella.png',
        },
        {
          timeline: '16',
          type: 'Death',
          episode: '35',
          episodeRelativeTC: '4:44',
          timecode: '36:31',
          absoluteTC: '14:46:28',
          description: 'Satella swallows Subaru, he is forced to commit suicide with Echidna\'s blessing.',
          image: '/images/deaths/death-16.png',
        },
      ],
    },
    {
      id: 17,
      startAt: '14:46:28',
      respawnId: 6,
      complexity: 4,
      direction: 'top',
      endAt: '15:34:20',
      events: [
        {
          timeline: '17',
          type: 'Event',
          episode: '36',
          episodeRelativeTC: '10:31',
          timecode: '32:17',
          absoluteTC: '15:18:45',
          description: 'Beatrice is stabbed by Elsa and Subaru is teleported back to Echidna\'s temple.',
          image: '/images/events/beatrice-stabbed-by-elsa.png',
        },
        {
          timeline: '17',
          type: 'Death',
          episode: '36',
          episodeRelativeTC: '26:06',
          timecode: '47:52',
          absoluteTC: '15:34:20',
          description: 'Roswaal is eaten by the Great Rabbit and Subaru dies in Emilia\'s arms inside Echidna\'s temple.',
          image: '/images/deaths/death-17.png',
        },
      ],
    },
    {
      id: 18,
      startAt: '15:34:20',
      respawnId: 6,
      complexity: 5,
      direction: 'top',
      endAt: '22:18:24',
      events: [
        {
          timeline: '18',
          type: 'Event',
          episode: '37',
          episodeRelativeTC: '12:32',
          timecode: '12:36',
          absoluteTC: '15:46:56',
          description: 'Subaru enters the temple to talk to Echidna, but the second trial begins. Echidna save Subaru before he loses his mind.',
          image: '/images/events/mad-echidna.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '38',
          episodeRelativeTC: '21:05',
          timecode: '48:09',
          absoluteTC: '16:22:29',
          description: 'Roswaal reveals that he is the one who hired the assassin attacking the mansion.',
          image: '/images/events/mad-roswaal.png',
        },
        {
          timeline: '18',
          type: 'SeasonSeparator',
          episode: '38',
          episodeRelativeTC: '27:00',
          timecode: '54:04',
          absoluteTC: '16:28:24',
          description: 'Season 2 (Part II)',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '39',
          episodeRelativeTC: '22:34',
          timecode: '1:16:38',
          absoluteTC: '16:50:58',
          description: 'Puck breaks his contract with Emilia',
          image: '/images/events/puck-breaks-contract.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '40',
          episodeRelativeTC: '15:47',
          timecode: '1:37:51',
          absoluteTC: '17:12:11',
          description: 'Emilia hides in the temple, Subaru joins her to console her.',
          image: '/images/events/subaru-consoles-emilia.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '40',
          episodeRelativeTC: '24:56',
          timecode: '1:47:00',
          absoluteTC: '17:21:20',
          description: 'Subaru kisses Emilia.',
          image: '/images/events/subaru-kisses-emilia.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '41',
          episodeRelativeTC: '11:17',
          timecode: '2:00:51',
          absoluteTC: '17:35:11',
          description: 'Subaru uses ',
          image: '/images/events/invisible-providence.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '41',
          episodeRelativeTC: '26:24',
          timecode: '2:15:58',
          absoluteTC: '17:50:18',
          description: 'Emilia retries second step.',
          image: '/images/events/emilia-retries-second-step.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '44',
          episodeRelativeTC: '23:35',
          timecode: '2:19:04',
          absoluteTC: '17:53:24',
          description: 'Emilia passes second step.',
          image: '/images/events/emilia-passes-second-step.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '46',
          episodeRelativeTC: '15:07',
          timecode: '2:48:34',
          absoluteTC: '18:22:54',
          description: 'Emilia tries third step.',
          image: '/images/events/emilia-tries-third-step.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '46',
          episodeRelativeTC: '22:12',
          timecode: '3:41:39',
          absoluteTC: '19:15:59',
          description: 'Ram and Puck start a fight with Roswaal',
          image: '/images/events/ram-puck-start-fight-against-roswaal.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '46',
          episodeRelativeTC: '26:05',
          timecode: '4:32:11',
          absoluteTC: '20:06:31',
          description: 'Subaru and Garfiel arrive at the mansion to save Petra, Frederica and Rem from Elsa and Meili',
          image: '/images/events/subaru-garfiel-arrive-at-mansion.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '47',
          episodeRelativeTC: '23:16',
          timecode: '4:39:16',
          absoluteTC: '20:13:36',
          description: 'Emilia passes third step.',
          image: '/images/events/emilia-passes-third-step.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '48',
          episodeRelativeTC: '7:55',
          timecode: '4:43:09',
          absoluteTC: '20:17:29',
          description: 'Emilia meets Minerva (Witch of Wrath).',
          image: '/images/events/emilia-meets-minerva.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '48',
          episodeRelativeTC: '21:13',
          timecode: '5:09:50',
          absoluteTC: '20:44:10',
          description: 'Garfiel crushes Elsa to death by throwing her the dead body of Meili\'s Wagpig (hypopotamus demon beast).',
          image: '/images/events/elsa-dies.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '48',
          episodeRelativeTC: '28:25',
          timecode: '5:23:29',
          absoluteTC: '20:57:49',
          description: 'Roswaal blows up Ram after she throws his Book of Wisdom in fire.',
          image: '/images/events/roswaal-blows-up-ram.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '49',
          episodeRelativeTC: '11:27',
          timecode: '5:36:47',
          absoluteTC: '21:11:07',
          description: 'The Great Rabbit appears where the fight between Ram, Puck and Roswaal ended.',
          image: '/images/events/great-rabbit-appears.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '49',
          episodeRelativeTC: '27:26',
          timecode: '5:43:59',
          absoluteTC: '21:18:19',
          description: 'Subaru finally manages to convince Beatrice to leave the burning mansion.',
          image: '/images/events/subaru-saves-beatrice-from-fire.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '50',
          episodeRelativeTC: '04:39',
          timecode: '5:56:31',
          absoluteTC: '21:30:51',
          description: 'Subaru, Emilia and Beatrice kill the Great Rabbit by trapping them and sending them to another dimension.',
          image: '/images/events/great-rabbit-killed.png',
        },
        {
          timeline: '18',
          type: 'Event',
          episode: '50',
          episodeRelativeTC: '22:57',
          timecode: '6:12:30',
          absoluteTC: '21:46:50',
          description: 'Subaru officially becomes Emilia\'s knight.',
          image: '/images/events/subaru-becomes-emilias-knight.png',
        },
      ],
    },
  ],
  episodes: [
    { id: 1, code: '1A', endAt: '25:16', title: 'The End of the Beginning and the Beginning of the End', length: '25:16' },
    { id: 2, code: '1B', endAt: '49:32', title: 'The End of the Beginning and the Beginning of the End', length: '24:16' },
    { id: 3, code: '2', endAt: '1:14:08', title: 'Reunion with the Witch', length: '24:36' },
    { id: 4, code: '3', endAt: '1:38:43', title: 'Starting Life from Zero in Another World', length: '24:35' },
    { id: 5, code: '4', endAt: '2:03:19', title: 'The Happy Roswaal Mansion Family', length: '24:36' },
    { id: 6, code: '5', endAt: '2:27:54', title: 'The Morning of Our Promise is Still Distant', length: '24:35' },
    { id: 7, code: '6', endAt: '2:52:30', title: 'The Sound of Chains', length: '24:36' },
    { id: 8, code: '7', endAt: '3:17:06', title: 'Natsuki Subaru\'s Restart', length: '24:36' },
    { id: 9, code: '8', endAt: '3:41:42', title: 'I Cried, Cried My Lungs Out, and Stopped Crying', length: '24:36' },
    { id: 10, code: '9', endAt: '4:06:18', title: 'The Meaning of Courage', length: '24:36' },
    { id: 11, code: '10', endAt: '4:30:54', title: 'Fanatical Methods Like a Demon', length: '24:36' },
    { id: 12, code: '11', endAt: '4:55:30', title: 'Rem', length: '24:36' },
    { id: 13, code: '12', endAt: '5:20:05', title: 'Return to the Capital', length: '24:35' },
    { id: 14, code: '13', endAt: '5:44:41', title: 'Self-Proclaimed Knight Natsuki Subaru', length: '24:36' },
    { id: 15, code: '14', endAt: '6:10:37', title: 'The Sickness Called Despair', length: '25:56' },
    { id: 16, code: '15', endAt: '6:35:13', title: 'The Face of Madness', length: '24:36' },
    { id: 17, code: '16', endAt: '6:59:48', title: 'The Greed of a Pig', length: '24:35' },
    { id: 18, code: '17', endAt: '7:24:24', title: 'Disgrace in the Extreme', length: '24:36' },
    { id: 19, code: '18', endAt: '7:50:20', title: 'From Zero', length: '25:56' },
    { id: 20, code: '19', endAt: '8:14:56', title: 'Battle Against the White Whale', length: '24:36' },
    { id: 21, code: '20', endAt: '8:39:32', title: 'Wilhelm van Astrea', length: '24:36' },
    { id: 22, code: '21', endAt: '9:04:01', title: 'A Wager That Defies Despair', length: '24:29' },
    { id: 23, code: '22', endAt: '9:28:29', title: 'A Flash of Sloth', length: '24:28' },
    { id: 24, code: '23', endAt: '9:52:58', title: 'Nefarious Sloth', length: '24:29' },
    { id: 25, code: '24', endAt: '10:17:26', title: 'The Self-Proclaimed Knight and the Greatest Knight', length: '24:28' },
    { id: 26, code: '25', endAt: '10:44:45', title: 'That\'s All This Story Is About', length: '27:19' },
    { id: 27, code: '26', endAt: '11:13:25', title: 'Each One\'s Promise', length: '28:40' },
    { id: 28, code: '27', endAt: '11:39:05', title: 'The Next Location', length: '25:40' },
    { id: 29, code: '28', endAt: '12:04:35', title: 'The Long-Awaited Reunion', length: '25:30' },
    { id: 30, code: '29', endAt: '12:34:05', title: 'Parent and Child', length: '29:30' },
    { id: 31, code: '30', endAt: '13:00:14', title: 'A Step Forward', length: '26:09' },
    { id: 32, code: '31', endAt: '13:26:24', title: 'The Maiden\'s Gospel', length: '26:10' },
    { id: 33, code: '32', endAt: '13:52:54', title: 'Friend', length: '26:30' },
    { id: 34, code: '33', endAt: '14:17:04', title: 'The Value of Life', length: '24:10' },
    { id: 35, code: '34', endAt: '14:41:44', title: 'Love Love Love Love Love Love You', length: '24:40' },
    { id: 36, code: '35', endAt: '15:08:14', title: 'I Know Hell', length: '26:30' },
    { id: 37, code: '36', endAt: '15:34:24', title: 'The Taste of Death', length: '26:10' },
    { id: 38, code: '37', endAt: '16:01:24', title: 'The Witches\' Tea Party', length: '27:00' },
    { id: 39, code: '38', endAt: '16:28:24', title: 'The Sounds That Make You Want to Cry', length: '27:00' },
    { id: 40, code: '39', endAt: '16:56:24', title: 'STRAIGHT BET', length: '00:28:00' },
    { id: 41, code: '40', endAt: '17:23:54', title: 'Otto Suwen / A Reason to Believe', length: '00:27:30' },
    { id: 42, code: '41', endAt: '17:53:24', title: 'Nobody Can Lift a Quain Stone Alone', length: '00:29:30' },
    { id: 43, code: '42', endAt: '18:22:54', title: 'A Journey Through Memories', length: '00:29:30' },
    { id: 44, code: '43', endAt: '18:52:24', title: 'The Day Betelgeuse Laughed', length: '00:29:30' },
    { id: 45, code: '44', endAt: '19:21:53', title: 'The Permafrost of Elior Forest', length: '00:29:30' },
    { id: 46, code: '45', endAt: '19:51:24', title: 'The Beginning of the Sanctuary and the Beginning of the End', length: '00:29:30' },
    { id: 47, code: '46', endAt: '20:20:54', title: 'Reunion of Roars', length: '00:29:00' },
    { id: 48, code: '47', endAt: '20:49:54', title: 'Happiness Reflected on the Water\'s Surface', length: '00:29:30' },
    { id: 49, code: '48', endAt: '21:19:24', title: 'Love Me Down to My Blood and Guts', length: '00:29:30' },
    { id: 50, code: '49', endAt: '21:48:54', title: 'Choose Me', length: '00:29:30' },
    { id: 51, code: '50', endAt: '22:18:24', title: 'Offbeat Steps Under the Moonlight', length: '00:29:30' },
  ],
};
