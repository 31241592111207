






import Vue from 'vue';
import TimelinesGraph from '@/components/TimelinesGraph.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    TimelinesGraph,
  },
});
