











import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      timeline: HTMLDivElement;
    };
  }
  >).extend({
  name: 'Timeline',
  methods: {
  },
});
