






import Vue from 'vue';

export default Vue.extend({
  name: 'SeasonSeparator',
  props: {
    description: String,
  },
});
