

























































import Vue from 'vue';

export default Vue.extend({
  name: 'TimelineJoint',
  props: {
    jointDirection: String,
    jointComplexity: Number,
  },
  data: () => ({
    spacing: 40,
  }),
  computed: {
    computedComplexity(): number {
      return this.jointComplexity + 1;
    },
  },
  methods: {
    svgHeight(jointComplexity: number) {
      return this.$data.spacing * jointComplexity;
    },
  },
});
